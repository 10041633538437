<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    :query-selects="querySelects"
    :relations="relations"
    entity-type="material"
    locale-section="pages.materials"
    field-section="materials"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "MaterialDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: material
        material
        lang {
          id: language
        }
        materialType {
          id
        }
        materialSubTypes {
          id
        }
      `,
      querySelects: /* GraphQL */ `
        lang: modernLanguages {
          id: language
          sv
          en
        }
        materialType: materialTypes {
          id
          materialType
        }
        materialSubTypes {
          id
          subType
        }
      `,
      inputAttributes: {
        material: {
          required: true
        },
        materialType: {
          required: true
        },
        lang: {
          required: true
        }
      },
      relations: {
        materialSubTypes: {
          id: null
        }
      }
    };
  }
};
</script>
